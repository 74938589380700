<template>
  <div v-if="loading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme="{
      ...theme,
      rtl: false,
      topMenu: true,
      darkMode: false,
    }"
  >
    <Suspense>
      <template #default>
        <router-view></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from "vue3-styled-components";
import { theme } from "./config/theme/themeVariables";
import {defineComponent, onMounted} from "vue";

export default defineComponent({
  name: "App",
  components: {
    ThemeProvider,
  },
  setup() {
    let loading = false
    onMounted(() => {
      loading = true;
    })
    return {
      loading,
      theme,
    };
  },
});
</script>
