import {DataService} from "@/config/dataService/dataService";

const state = () => ({
    data: {},
    isLoading: false,
    error: null,
});

const mutations = {
    completeShipmentLoad(state, data) {
        state.data = data;
        state.isLoading = true;
        state.error = null;
    },
    shipmentInitialState(state) {
        state.data = {};
        state.isLoading = false;
        state.error = null
    }
}

const actions = {
    async getShipments({ commit }) {
        try {
            commit("shipmentInitialState");
            const shipment = await DataService.get("shipment/portal?guid=f8e3ca42-1d1e-4a5e-aed0-1157abb3ab55&token=sdfasdf");
            console.dir(shipment.data);
            return commit("completeShipmentLoad", shipment.data);
        } catch (err) {
            commit("shipmentInitialState");
        }
    }
}

export default {
    namespaced: false,
    state,
    actions,
    mutations
}