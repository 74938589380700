import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    name: 'Main',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/PrimaryLayout.vue'),
    children: [
      {
        path: '',
        name: 'package',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/apps/landing/index.vue'),
      },
    ],
    meta: {auth: false},
  },
  {
    name: 'RequestAccess',
    path: '/request-access',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/RequestShipmentLayout.vue'),
    children: [
      {
        path: '',
        name: 'sign-in',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/authentication/SignIn.vue'),
      }
    ],
    meta: {auth: false},
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

// router.beforeEach((to, from, next) => {
//   // console.log(to, store);
//   if (to.meta.auth && store.state.auth.login) {
//     next({ to: '/' });
//   } else if (!to.meta.auth && !store.state.auth.login) {
//     next({ name: 'login' });
//   } else {
//     next();
//   }
// });

export default router;
